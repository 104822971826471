import { Component } from '@angular/core';
import { IconComponent } from '@shared/components/base/icon/icon.component';

@Component({
  selector: 'pn-loading',
  standalone: true,
  imports: [IconComponent],
  template: '<pn-icon icon="loading" width="50" height="50"></pn-icon>\n',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent {}
