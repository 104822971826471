/* src/app/_shared/components/base/loading/loading.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: flex;
  justify-content: center;
  margin: 60px 0;
  --icon-fill: #338054;
}
/*# sourceMappingURL=loading.component.css.map */
